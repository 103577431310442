import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class HeaderController extends Controller {
  static targets = ["menuItem", "navigationToggle", "userToggle", "radioReset", "submenu", "flash"];

  connect() {
    useClickOutside(this, { events: ["click"] });
    this.startObservingScrollPosition();
  }

  disconnect() {
    this.stopObservingScrollPosition();
  }

  startObservingScrollPosition() {
    // We use a 1×1px <div> at the top of the page to check whether we are at the top of the viewport or not. When we are intersecting with the element, we must be right at the top of the viewport – otherwise we wouldn't intersect it.

    if (!window.IntersectionObserver) return;
    if (!this.element.classList.contains("header-main--transparent")) return;

    this.measureScrollElement = document.createElement("div");
    this.measureScrollElement.style.cssText = "position: absolute; top: 0; left: 0; width: 1px; height: 1px;";

    this.element.before(this.measureScrollElement);

    this.observer = new IntersectionObserver(
      (events) => {
        const event = events[0];

        this.element.classList.toggle("is-scrolled-to-top", event.isIntersecting);
      },
      {
        threshold: [1],
      }
    );

    this.observer.observe(this.measureScrollElement);
  }

  stopObservingScrollPosition() {
    this.observer?.disconnect();
    this.measureScrollElement?.remove();
  }

  handleClick(event) {
    // When clicking the nav item, we also toggle the checkbox, which triggers another click event.
    // We have to filter this out, because it would prevent the menu from closing.
    if (event.target.matches("input[type='checkbox']")) return;

    const alreadyClicked = event.currentTarget.classList.contains("clicked");

    this.resetMenu();

    this.radioResetTarget.checked = true;

    if (!alreadyClicked) {
      event.currentTarget.classList.add("clicked");
      this.element.classList.add("menu_open");
    }

    this.updateFlash();
  }

  handleUserClick(event) {
    this.navigationToggleTarget.checked = false;
    this.handleClick(event);
  }

  handleNavigationClick(event) {
    if (this.hasUserToggleTarget) {
      this.userToggleTarget.checked = false;
    }
    this.handleClick(event);
  }

  resetMenu() {
    this.menuItemTargets.forEach((element) => {
      element.classList.remove("clicked");
    });

    this.radioResetTarget.checked = false;

    this.element.classList.remove("menu_open");
  }

  updateFlash() {
    if (!this.hasFlashTarget) return;

    const activeSubmenu = this.submenuTargets.find((submenuElement) =>
      submenuElement.matches(".clicked .main-submenu")
    );
    const offsetTop = `${(activeSubmenu?.offsetHeight || 0) + 64}px`;

    this.flashTargets.forEach((flashElement) => {
      flashElement.style.top = offsetTop;
    });
  }

  clickOutside() {
    this.resetMenu();
    this.updateFlash();
  }
}
