window.hj =
  window.hj ||
  function () {
    (hj.q = hj.q || []).push(arguments);
  };

export function identify(userId) {
  window.hj("identify", userId);
}

function loadHotjar() {
  if (!process.env.HOTJAR_KEY) return;
  if (!App.cookieConsentStore?.read().evaluation_enabled) return;

  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: process.env.HOTJAR_KEY, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

App.cookieConsentStore?.subscribe(() => loadHotjar());
loadHotjar();
