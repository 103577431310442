window._hsq = window._hsq || [];

function loadHubSpot() {
  if (!process.env.HUBSPOT_PORTAL_ID) return;
  if (!App.cookieConsentStore?.read().evaluation_enabled) return;

  const script = document.createRange().createContextualFragment(`
    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/${process.env.HUBSPOT_PORTAL_ID}.js"></script>
  `);

  // Documentation says it should go  just before the </body> tag.
  document.body.append(script);

  // We set the doNotTrack option on HubSpot landing pages and reset it there after consent was given.
  // If someone only gives consent on the landing page, we need to activate tracking here as well.
  // See: https://app.hubspot.com/settings/1881640/website/pages/all-domains/page-templates
  window._hsq.push(["doNotTrack", { track: true }]);
}

App.cookieConsentStore?.subscribe(() => loadHubSpot());
loadHubSpot();
