export async function installCurrencyInputs() {
  const inputs = document.querySelectorAll("input[data-type=currency]");
  if (inputs.length === 0) return;

  const AutoNumeric = (await import("autonumeric")).default;
  inputs.forEach((input) => initializeCurrencyInput(input, AutoNumeric));
}

/**
 * @param {HTMLInputElement} inputElement
 * @param {typeof import("autonumeric")} AutoNumeric
 * @returns {Promise<AutoNumeric>}
 *
 * Initialize a currency input field. If the input field is already managed by
 * AutoNumeric, the existing AutoNumeric instance is returned. Thus, you can
 * call this function inside a Stimulus controller's initialize() method without
 * worrying about whether the input field has already been initialized.
 */
export function initializeCurrencyInput(inputElement, AutoNumeric) {
  if (AutoNumeric.isManagedByAutoNumeric(inputElement)) {
    return AutoNumeric.getAutoNumericElement(inputElement);
  }

  const an = new AutoNumeric(inputElement, {
    ...AutoNumeric.getPredefinedOptions().euroPos,
    decimalCharacterAlternative: null, // disables automatic conversion of '.' into ','
    modifyValueOnWheel: false, // disables increment or decrement the value with the mouse wheel
    emptyInputBehavior: "press",
    overrideMinMaxLimits: "invalid",
  });

  const { min, max, allowEmpty } = inputElement.dataset;

  if (min) {
    an.update({ minimumValue: min });

    inputElement.addEventListener("autoNumeric:minExceeded", function () {
      if (allowEmpty && AutoNumeric.getNumber(inputElement) === 0) {
        inputElement.setCustomValidity("");
        return;
      }
      inputElement.setCustomValidity(`muss größer oder gleich ${an.formatOther(min)} sein`);
    });
  }

  if (max) {
    an.update({ maximumValue: max });

    inputElement.addEventListener("autoNumeric:maxExceeded", function () {
      inputElement.setCustomValidity(`muss kleiner oder gleich ${an.formatOther(max)} sein`);
    });
  }

  return an;
}
