import { Application } from "@hotwired/stimulus";
import StimulusControllerResolver, { createViteGlobResolver } from "stimulus-controller-resolver";
import HeaderController from "../controllers/header-controller";

const application = Application.start();
// Eager load these special controllers:
application.register("header-controller", HeaderController);

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(
    import.meta.glob("../controllers/*-controller.js"),
    import.meta.glob("../../components/**/*-controller.js"),
    rewriteViewComponentControllers(import.meta.glob("../../components/**/controller.js"))
  )
);

// To make sure that the ViteGlobResolver understands our controllers, we need to map
//   components/example/controller.js to
//   components/example-controller.js
function rewriteViewComponentControllers(globResult) {
  const rewrittenEntries = Object.entries(globResult).map(([key, value]) => [
    key.replace("/controller.js", "-controller.js"),
    value,
  ]);

  return Object.fromEntries(rewrittenEntries);
}
