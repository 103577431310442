export async function installCodemirror() {
  const textareas = document.querySelectorAll("textarea[data-cms-cm-mode]");
  if (textareas.length === 0) return;

  await import("~/lib/codemirror.css");
  const { basicSetup } = await import("codemirror");
  const { EditorView, keymap } = await import("@codemirror/view");
  const { html } = await import("@codemirror/lang-html");
  const { indentWithTab } = await import("@codemirror/commands");

  const extensions = [basicSetup, EditorView.lineWrapping, keymap.of([indentWithTab]), html()];

  textareas.forEach((textarea) => {
    const view = new EditorView({ doc: textarea.value, extensions: extensions });

    textarea.parentNode.insertBefore(view.dom, textarea);
    textarea.style.display = "none";

    if (textarea.form)
      textarea.form.addEventListener("submit", () => {
        textarea.value = view.state.doc.toString();
      });
  });
}
