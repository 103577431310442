import "./google-tag-manager";
import { identify as identifyForGoogleAnalytics } from"./google-analytics";
import { identify as identifyForHotjar } from "./hotjar";
import "./hubspot";

function identify(userId) {
  identifyForGoogleAnalytics(userId);
  identifyForHotjar(userId);
}

function page() {
  // TODO test if page_view is automatically tracked over history change
  // see https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
  window.dataLayer.push({ event: "page_view" });
}

function track(event, properties) {
  window.dataLayer.push({ event, ...properties });
}

// analytics.trackLink this, 'link_clicked', { category: category, label: label, value: value, user_id: user }
function trackLink(element, event, properties) {
  element.addEventListener("click", (e) => {
    window.dataLayer.push({ event, ...properties });
  });
}

window.analytics = { identify, page, track, trackLink };
