// Source: https://stackoverflow.com/a/41343061
function proxy(context, method) {
  return function() {
    const message = Array.prototype.slice.apply(arguments)

    if (typeof Sentry !== "undefined") Sentry.captureMessage(message, "warning");

    method.apply(context, message)
  }
}
console.warn = proxy(console, console.warn)

/*
Testcode to trigger a jQuery warning in coffee
$ ->
  $(".my-class").size()
  # will call a warning: MIGRATE: jQuery.fn.size() is deprecated; use the .length property
 */
